import { all, call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { ISOLATED_SSO_AUTHENTICATION_ATTEMPT, ISOLATED_SSO_AUTHENTICATION_SUCCESS } from './actionTypes';
import { isolatedSsoAuthFailure, isolatedSsoAuthSuccess, setSource } from './actions';
import { SelectState, TypedIterableIterator } from '../../modules/helpers';
import { createHttpClient } from '../../services/httpRequest';

import { getBrandNameSelector } from '../BrandProvider/selectors';

import AWS from 'aws-sdk';
import { IsolatedSsoAuthAttemptType, IsolatedSsoAuthSuccessType } from './types';
import queryString from 'query-string';

AWS.config.update({ region: 'us-east-2' });

function createAuthorizationHeader(token: any, brand: string) {
  return 'Basic ' + Buffer.from(`${brand}:${token}`).toString('base64');
}

async function callEndpoint(token: any, brand: string) {
  const basicAuthHeader = createAuthorizationHeader(token, brand);

  const httpClient = createHttpClient(brand, 'authentication');
  try {
    const res = await httpClient.post(
      '/byToken',
      {},
      {
        headers: { Authorization: basicAuthHeader },
      },
    );

    if (!res || !res.data || res.data.errorMessage) {
      throw new Error('invalid login');
    }
    return res.data;
  } catch (error) {
    throw error;
  }
}

function* attemptSsoAuth(payload: IsolatedSsoAuthAttemptType): TypedIterableIterator<any> {
  const paramsQuery = queryString.parse(payload.location.search);
  const { token, nextPath, source } = paramsQuery;

  if (source === 'ios' || source === 'android') {
    yield put(setSource(source));
  }

  try {
    if (token) {
      const brand: string = yield SelectState<string>(getBrandNameSelector);
      const results = yield call(callEndpoint, token, brand);
      yield put(isolatedSsoAuthSuccess(results.email, results, nextPath ? nextPath.toString() : ''));
    } else {
      throw new Error('No token provided');
    }
  } catch (err) {
    yield put(isolatedSsoAuthFailure(err));
    yield put(push('/login'));
  }
}

function* redirectToNextPath(payload: IsolatedSsoAuthSuccessType): TypedIterableIterator<any> {
  const { nextPath } = payload;

  if (nextPath && nextPath !== 'undefined') {
    const nextRoute = decodeURIComponent(nextPath);
    console.log('Routing to next path: ', nextRoute);
    yield put(push(nextPath.charAt(0) === '/' ? nextRoute : `/${nextRoute}`));
  } else {
    console.error('Next Path invalid, sending to home page');
    yield put(push('/subscriptions'));
  }
}

function* isolatedSsoAuthenticationRedirectSaga() {
  yield all([takeLatest(ISOLATED_SSO_AUTHENTICATION_ATTEMPT, attemptSsoAuth)]);
  yield all([takeLatest(ISOLATED_SSO_AUTHENTICATION_SUCCESS, redirectToNextPath)]);
}

export default isolatedSsoAuthenticationRedirectSaga;
