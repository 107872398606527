import { SsoRedirectActionTypes } from './types';

import {
  SSO_AUTHENTICATION_ATTEMPT,
  SSO_AUTHENTICATION_FAILURE,
  SSO_AUTHENTICATION_SUCCESS,
  PLAN_CONFIGS_FAILURE,
  PLAN_CONFIGS_SUCCESS,
  SSO_SUBSCRIPTIONS_DEVICES_FAILURE,
  SSO_SUBSCRIPTIONS_DEVICES_SUCCESS,
} from './actionTypes';

import { Location } from 'history';

export const ssoAuthAttempt = (location: Location): SsoRedirectActionTypes => ({
  type: SSO_AUTHENTICATION_ATTEMPT,
  location: location,
});

export const ssoAuthFailure = (error: any): SsoRedirectActionTypes => ({
  type: SSO_AUTHENTICATION_FAILURE,
  error: error,
});

export const ssoAuthSuccess = (
  username: any,
  profile: any,
  toPlanCode: any,
  fromPlanCode: any,
  nextPath: any,
): SsoRedirectActionTypes => ({
  type: SSO_AUTHENTICATION_SUCCESS,
  username: username,
  profile: profile,
  toPlanCode: toPlanCode,
  fromPlanCode: fromPlanCode,
  nextPath: nextPath,
});

export const getPlanConfigFailure = (error: any): SsoRedirectActionTypes => ({
  type: PLAN_CONFIGS_FAILURE,
  error: error,
});

export const getPlanConfigSuccess = (planConfigs: any): SsoRedirectActionTypes => ({
  type: PLAN_CONFIGS_SUCCESS,
  planConfigs: planConfigs,
});

export const subscriptionsWithDevicesFailure = (error: any): SsoRedirectActionTypes => ({
  type: SSO_SUBSCRIPTIONS_DEVICES_FAILURE,
  error: error,
});

export const subscriptionsWithDevicesSuccess = (subscriptionPlans: any): SsoRedirectActionTypes => ({
  type: SSO_SUBSCRIPTIONS_DEVICES_SUCCESS,
  subscriptionPlans: subscriptionPlans,
});
