import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import messages from "./messages";

/**
 * User's name and sign out link for when user is signed in.
 *
 * @param props
 * @constructor
 */
const SignOut = (props: any) => {
  const { brand, fullName } = props;

  return (
    <div className={`${brand}-log-out`}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {fullName}
      </Typography>
      <Link id="signOut" className={`${brand}-log-out-link`} to="/">
        <FormattedMessage {...messages.signOut}/>
      </Link>
    </div>
  );
};

export default SignOut;