import { all, fork } from 'redux-saga/effects';

import authenticateSaga from '../containers/LoginPage/sagas';
import loadSubscriptionPlansSaga from '../containers/SubscriptionsList/sagas';
import loadPlansSage from '../containers/PlanListPage/sagas';
import paymentInformationSaga from '../containers/PaymentInformation/sagas';
import deviceListSaga from '../containers/DeviceList/sagas';
import AccountHistorySaga from '../containers/AccountHistory/sagas';
import ManageDevicesSaga from '../containers/ManageDevices/sagas';
import BillingInformationViewSaga from '../containers/BillingInformationView/sagas';
import OrderSummarySaga from '../containers/OrderSummary/sagas';
import PromoCodeSaga from '../containers/PromoCode/sagas';
import CancelPlanSaga from '../containers/CancelPlan/sagas';
import VerifyEmailSaga from '../containers/VerifyEmail/sagas';
import ResetPasswordSaga from '../containers/ResetPassword/sagas';
import ssoAuthenticationRedirectSaga from '../containers/SsoRedirect/sagas';
import cancelDeleteAccountSaga from '../containers/DeleteAccountModel/sagas';
import CheckoutSessionSaga from './CheckoutSession/sagas';
import CheckoutSuccessSaga from '../containers/CheckoutSuccess/sagas';
import AnalyticsSaga from '../utils/analytics/sagas';
import UnifiedCheckoutSaga from '../containers/UnifiedCheckout/sagas';
import isolatedSsoAuthenticationRedirectSaga from '../containers/SsoIsolated/sagas';

export function* rootSaga() {
  yield all([
    fork(AnalyticsSaga),
    fork(authenticateSaga),
    fork(loadSubscriptionPlansSaga),
    fork(loadPlansSage),
    fork(paymentInformationSaga),
    fork(deviceListSaga),
    fork(AccountHistorySaga),
    fork(ManageDevicesSaga),
    fork(BillingInformationViewSaga),
    fork(OrderSummarySaga),
    fork(PromoCodeSaga),
    fork(CancelPlanSaga),
    fork(VerifyEmailSaga),
    fork(ResetPasswordSaga),
    fork(ssoAuthenticationRedirectSaga),
    fork(isolatedSsoAuthenticationRedirectSaga),
    fork(cancelDeleteAccountSaga),
    fork(CheckoutSessionSaga),
    fork(CheckoutSuccessSaga),
    fork(UnifiedCheckoutSaga),
  ]);
}
