export const AUTHENTICATION_ATTEMPT = 'AUTHENTICATION_ATTEMPT';
export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';
export const AUTHENTICATION_FAILURE = 'AUTHENTICATION_FAILURE';
export const SIGN_OUT_INITIATED = 'SIGN_OUT_INITIATED';
export const SIGN_OUT_SUCCESSFUL = 'SIGN_OUT_SUCCESSFUL';
export const SSO_ATTEMPT = 'SSO_ATTEMPT';
export const SSO_SUCCESS = 'SSO_SUCCESS';
export const SSO_FAILURE = 'SSO_FAILURE';
export const CLEAR_STATE = 'CLEAR_STATE';// This will clear state across the application
export const CLEAR_MFA_STATE = 'CLEAR_MFA_STATE';
export const AUTHENTICATION_PATCH_PROFILE = 'AUTHENTICATION_PATCH_PROFILE';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const SOCIAL_AUTHENTICATION_ATTEMPT = 'SOCIAL_AUTHENTICATION_ATTEMPT';
export const SOCIAL_AUTHENTICATION_SUCCESS = 'SOCIAL_AUTHENTICATION_SUCCESS';
export const SOCIAL_AUTHENTICATION_FAILURE = 'SOCIAL_AUTHENTICATION_FAILURE';
export const SET_LOGIN_VIEW = 'SET_LOGIN_VIEW';
export const AUTHENTICATION_NEEDS_MFA = 'AUTHENTICATION_NEEDS_MFA';
export const MFA_VERIFICATION_ATTEMPT = 'MFA_VERIFICATION_ATTEMPT';
export const MFA_VERIFICATION_SUCCESS = 'MFA_VERIFICATION_SUCCESS';
export const MFA_VERIFICATION_FAILURE = 'MFA_VERIFICATION_FAILURE';
export const MFA_RESEND_VERIFICATION_ATTEMPT = 'MFA_RESEND_VERIFICATION_ATTEMPT';
export const MFA_RESEND_VERIFICATION_SUCCESS = 'MFA_RESEND_VERIFICATION_SUCCESS';
export const MFA_RESEND_VERIFICATION_FAILURE = 'MFA_RESEND_VERIFICATION_FAILURE';

export const SET_IS_OAUTH_LOGIN = 'SET_IS_OAUTH_LOGIN';
export const OAUTH_AUTHENTICATION_SUCCESS = 'OAUTH_AUTHENTICATION_SUCCESS';
export const TRIGGER_MISSING_PARAMETERS_ERROR = 'TRIGGER_MISSING_PARAMETERS_ERROR';
export const SET_ACCOUNT_LINKING_PARTNER = 'SET_ACCOUNT_LINKING_PARTNER';
export const SET_OAUTH_PARAMETERS = 'SET_OAUTH_PARAMETERS';