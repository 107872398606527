import { LoginActionTypes, MfaData, MfaResendData, OAuthParameters, UserMFASettings } from './types';
import {
  AUTHENTICATION_ATTEMPT,
  AUTHENTICATION_FAILURE,
  AUTHENTICATION_NEEDS_MFA,
  AUTHENTICATION_PATCH_PROFILE,
  AUTHENTICATION_SUCCESS,
  CLEAR_ERROR,
  CLEAR_MFA_STATE,
  CLEAR_STATE,
  MFA_RESEND_VERIFICATION_ATTEMPT,
  MFA_RESEND_VERIFICATION_FAILURE,
  MFA_RESEND_VERIFICATION_SUCCESS,
  MFA_VERIFICATION_ATTEMPT,
  MFA_VERIFICATION_FAILURE,
  MFA_VERIFICATION_SUCCESS,
  REFRESH_TOKEN,
  SET_ACCOUNT_LINKING_PARTNER,
  SET_IS_OAUTH_LOGIN,
  SET_OAUTH_PARAMETERS,
  SIGN_OUT_INITIATED,
  SIGN_OUT_SUCCESSFUL,
  SOCIAL_AUTHENTICATION_ATTEMPT,
  SOCIAL_AUTHENTICATION_FAILURE,
  SOCIAL_AUTHENTICATION_SUCCESS,
  SSO_ATTEMPT,
  SSO_FAILURE,
  SSO_SUCCESS,
  TRIGGER_MISSING_PARAMETERS_ERROR,
} from './actionTypes';
import { SSO_AUTHENTICATION_SUCCESS } from '../SsoRedirect/actionTypes';
import { SsoRedirectActionTypes } from '../SsoRedirect/types';
import { ISOLATED_SSO_AUTHENTICATION_SUCCESS } from '../SsoIsolated/actionTypes';
import { IsolatedSsoRedirectActionTypes } from '../SsoIsolated/actions';

export interface IAuthenticationTypes {
  username: string | undefined;
  password: string | undefined;
  errorCode: any;
  attempting: boolean;
  authenticated: boolean | undefined;
  rememberMe: boolean;
  ssoAttempting: boolean;
  profile: any;
  token: any;
  mfaData?: MfaData;
  mfaResendData?: MfaResendData;
  isOAuthLogin: boolean;
  authCode?: string;
  oauthParameters?: OAuthParameters;
  accountLinkingPartner?: string;
  userMFASettings?: UserMFASettings;
}

export const initialState: IAuthenticationTypes = {
  username: '',
  password: '',
  errorCode: null,
  attempting: false,
  authenticated: false,
  rememberMe: false,
  ssoAttempting: false,
  profile: {},
  token: {},
  mfaData: undefined,
  mfaResendData: undefined,
  isOAuthLogin: false,
  authCode: undefined,
  oauthParameters: undefined,
  accountLinkingPartner: undefined,
  userMFASettings: undefined,
};

export const AuthenticationReducer = (
  state = initialState,
  action: LoginActionTypes | SsoRedirectActionTypes | IsolatedSsoRedirectActionTypes,
): IAuthenticationTypes => {
  switch (action.type) {
    case AUTHENTICATION_ATTEMPT:
      return {
        ...state,
        username: action.userInfo.username,
        password: action.userInfo.password,
        rememberMe: action.userInfo.rememberMe,
        attempting: true,
        errorCode: null,
      };
    case AUTHENTICATION_SUCCESS:
      return {
        ...state,
        username: action.username,
        password: '',
        profile: action.profile,
        attempting: false,
        errorCode: null,
        authenticated: true,
      };
    case AUTHENTICATION_FAILURE:
      return {
        ...state,
        password: '',
        attempting: false,
        errorCode: action.errorCode,
      };
    case SSO_ATTEMPT:
      return {
        ...state,
        token: action.token,
        ssoAttempting: true,
        errorCode: null,
      };
    case SSO_SUCCESS:
    case SSO_AUTHENTICATION_SUCCESS:
    case ISOLATED_SSO_AUTHENTICATION_SUCCESS:
      return {
        ...state,
        username: action.username,
        profile: action.profile,
        authenticated: true,
        ssoAttempting: false,
      };
    case SSO_FAILURE:
      return {
        ...state,
        errorCode: action.errorCode,
        password: undefined,
        ssoAttempting: false,
      };
    case SIGN_OUT_INITIATED:
      return {
        ...state,
        username: '',
        authenticated: false,
      };
    case SIGN_OUT_SUCCESSFUL:
      return {
        ...state,
        username: '',
        authenticated: false,
      };
    case AUTHENTICATION_PATCH_PROFILE:
      return {
        ...state,
        profile: action.profile,
      };
    case CLEAR_STATE:
      return {
        ...state,
        profile: undefined,
        attempting: false,
        authenticated: undefined,
        ssoAttempting: false,
        mfaResendData: undefined,
        mfaData: undefined,
        oauthParameters: undefined,
        accountLinkingPartner: undefined,
      };
    case CLEAR_MFA_STATE:
      return {
        ...state,
        mfaData: undefined,
        mfaResendData: undefined,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        errorCode: null,
      };
    case REFRESH_TOKEN:
      return {
        ...state,
        profile: {
          ...state.profile,
          token: action.token,
        },
      };
    case SOCIAL_AUTHENTICATION_ATTEMPT:
      return {
        ...state,
        rememberMe: true,
        attempting: true,
        errorCode: null,
      };
    case SOCIAL_AUTHENTICATION_SUCCESS:
      return {
        ...state,
        profile: action.payload.profile,
        attempting: false,
        errorCode: null,
        authenticated: true,
      };
    case SOCIAL_AUTHENTICATION_FAILURE:
      return {
        ...state,
        attempting: false,
        errorCode: true,
        authenticated: false,
      };
    case AUTHENTICATION_NEEDS_MFA:
      return {
        ...state,
        mfaData: !!action.mfaData
          ? {
              ...action.mfaData,
            }
          : undefined,
        userMFASettings: action.userMFASettings,
        errorCode: null,
        attempting: false,
      };
    case MFA_VERIFICATION_ATTEMPT:
      return {
        ...state,
        attempting: true,
        errorCode: null,
      };
    case MFA_VERIFICATION_SUCCESS:
      return {
        ...state,
        username: action.username,
        password: '',
        profile: action.profile,
        attempting: false,
        errorCode: null,
        authenticated: true,
        mfaData: undefined,
      };
    case MFA_VERIFICATION_FAILURE:
      return {
        ...state,
        attempting: false,
        errorCode: action.errorCode,
      };
    case MFA_RESEND_VERIFICATION_ATTEMPT:
      return {
        ...state,
        mfaResendData: {
          loading: true,
          resent: false,
          errorCode: undefined,
        },
      };
    case MFA_RESEND_VERIFICATION_SUCCESS:
      return {
        ...state,
        mfaResendData: {
          loading: false,
          resent: true,
          errorCode: undefined,
        },
        errorCode: undefined,
      };
    case MFA_RESEND_VERIFICATION_FAILURE:
      return {
        ...state,
        mfaResendData: {
          loading: false,
          resent: false,
          errorCode: action.errorCode,
        },
        errorCode: undefined,
      };
    case TRIGGER_MISSING_PARAMETERS_ERROR:
      return {
        ...state,
        errorCode: action.errorCode,
      };
    case SET_IS_OAUTH_LOGIN:
      return {
        ...state,
        isOAuthLogin: action.isOAuthLogin,
      };
    case SET_ACCOUNT_LINKING_PARTNER:
      return {
        ...state,
        accountLinkingPartner: action.partner,
      };

    case SET_OAUTH_PARAMETERS:
      return {
        ...state,
        oauthParameters: action.params,
      };

    default:
      return state;
  }
};
