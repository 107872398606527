import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { brandNames } from '../../constants/brandNames';
import history from '../../history';
import { getCurrencySelector } from '../BillingInformationView/selectors';
import { getBrandNameSelector } from '../BrandProvider/selectors';
import { getIsAuthenticatedSelector } from '../LoginPage/selectors';
import { subscriptionClearState } from '../OrderSummary/actions';
import { getSubscriptionPlansAttempt } from '../SubscriptionsList/actions';
import { getAttemptingSubscriptionPlansSelector, getCurrentSubscription } from '../SubscriptionsList/selectors';
import PlanActionControl from './PlanActionControl';
import TabControl from './TabControl';
import { getPlansAttempt, setCurrentPlan, setSelectedPlan } from './actions';
import messages from './messages';
import { getCurrentPlanSelector, getLoadingPlansSelector, getPlansListSelector } from './selectors';
import { Plan } from './types';
import { setPredefinedPromoCodes } from '../PromoCode/actions';

const MONTHLY_PLANS_TAB_INDEX = 0;
const YEARLY_PLANS_TAB_INDEX = 1;
const TWO_YEAR_PLANS_TAB_INDEX = 2;

const PLAN_ID_QUERY_PARAM = 'planId';
const PROMO_CODE_QUERY_PARAM = 'promoCode';

const cardBlackList = ['no_plan', 'visi_no_plan', 'pepper_lite', 'not_a_plan_geeni'];

const isMonthlyPlan = (plan: any, brand: string) =>
  plan?.billing_cycle < 12 || (brand === brandNames.pepper && plan?.billing_cycle < 12);

const isYearlyPlan = (plan: any) => plan?.billing_cycle >= 12 && plan?.billing_cycle < 24;

const isTwoYearPlan = (plan: any) => plan?.billing_cycle >= 24;

const isLegacyPlan = (plan: any) => plan?.legacy === true;

const determinePlanTabIndex = (plan: any, brand: string) => {
  if (isMonthlyPlan(plan, brand)) return MONTHLY_PLANS_TAB_INDEX;
  else if (isYearlyPlan(plan)) return YEARLY_PLANS_TAB_INDEX;
  else if (isTwoYearPlan(plan)) return TWO_YEAR_PLANS_TAB_INDEX;
  else return MONTHLY_PLANS_TAB_INDEX;
};

function TabPanel(props: any) {
  const { children, value, index, brand, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3} className={`${brand}-tab-panel-control`}>
          {children}
        </Box>
      )}
    </div>
  );
}

const PlanListPage = () => {
  const location = useLocation();
  const isUserAuthenticated = useSelector(getIsAuthenticatedSelector);
  const [currentTabIndex, setCurrentTabIndex] = React.useState(MONTHLY_PLANS_TAB_INDEX);
  const currentPlan = useSelector(getCurrentPlanSelector);
  const brand = useSelector(getBrandNameSelector);
  const isFetchingPlans = useSelector(getLoadingPlansSelector);
  const isFetchingSubscriptionPlans = useSelector(getAttemptingSubscriptionPlansSelector);
  const plans = useSelector(getPlansListSelector);
  const currency = useSelector(getCurrencySelector);

  const dispatch = useDispatch();

  // The route to ths component is purposely unguarded so that the plan selection page can be viewed by a user who is
  // not signed in.  If there is no user profile then we'll assume the user is not signed in.
  const isUserSignedIn = !!isUserAuthenticated;

  // If the user is signed in, then the loading indicator should be visible until currentPlan and selectedPlan are set.
  // Otherwise, it should be visible until plans have been fetched.
  const isLoadingIndicatorVisible = isUserSignedIn
    ? isFetchingPlans || isFetchingSubscriptionPlans || !currentPlan
    : isFetchingPlans || !plans;

  useEffect(() => {
    const fetchPlans = () => {
      dispatch(subscriptionClearState());
      dispatch(getSubscriptionPlansAttempt());
    };

    fetchPlans();
  }, [dispatch]);

  const currentSubscription = useSelector(getCurrentSubscription);

  // If there is a planId query parameter, then the user has arrived here from selecting a plan while not signed in.  In
  // that case, that plan id takes precedence over the user's current plan, if any.  Note that the user could have
  // picked the same plan they are already subscribed to, or a downgrade.  This is not a consideration for the business
  // because this is a temporary solution.  If there is no planId parameter, then the selected plan id will be the
  // user's current plan if there is one, and undefined otherwise.  Note that the selected plan id will also be undefined
  // when the user arrives here while not signed in because currentSubscription will be undefined.
  const [selectedPlanId, setSelectedPlanId] = useState<string | undefined>(() => {
    return new URLSearchParams(location.search).get(PLAN_ID_QUERY_PARAM) ?? currentSubscription?.plan_code;
  });

  // Users may arrive via a promo link.  If so, the promo may only apply to certain plans. For this reason, we are isolating
  // the available plans to the one that's passed in the promo link. This is temporary, but will enable more functionality in the future.
  const promoCodes = useMemo<string[] | undefined>(() => {
    // FIXME: PLAY-15303: if we want to support multiple promoCodes, we'll need to update this to getAll
    const promoSearchParams = new URLSearchParams(location.search).get(PROMO_CODE_QUERY_PARAM);
    return promoSearchParams ? [promoSearchParams] : undefined;
  }, [location.search]);

  // Set PromoCodes State with these parameters
  useEffect(() => {
    if (!promoCodes) return;
    dispatch(setPredefinedPromoCodes(promoCodes));
  }, [dispatch, promoCodes]);

  // If we have a promo_code, then the promo is likely only valid for certain plans. For now, we will restrict this to
  // the pre-selected option in the URL. This is temporary, but will enable more functionality in the future Customer Dashboard implementation.
  const eligiblePlans = useMemo<string[] | undefined>(() => {
    return promoCodes && promoCodes?.length > 0 && selectedPlanId ? [selectedPlanId] : undefined;
  }, [selectedPlanId, promoCodes]);

  const selectedPlan = useMemo(() => {
    return plans.find(plan => plan.id === selectedPlanId);
  }, [plans, selectedPlanId]);

  useEffect(() => {
    dispatch(getPlansAttempt(brand));
  }, [dispatch, brand]);

  useEffect(() => {
    if (!selectedPlanId && currentPlan) {
      if (!currentPlan.default) {
        setSelectedPlanId(currentPlan.id);
      }
    }
  }, [selectedPlanId, currentPlan]);

  useEffect(() => {
    setCurrentTabIndex(determinePlanTabIndex(selectedPlan, brand));
  }, [selectedPlan, brand]);

  /**
   * The selected/current plan redux store should be updated when selections are made. If the value isn't synced until
   * submission, any checks that happen when hitting submit or shortly after will be inaccurate. Specifically when
   * determining whether 'delegatedCheckout' should happen, this will not be executed properly.
   */
  useEffect(() => {
    dispatch(setCurrentPlan(currentPlan));
  }, [currentPlan, dispatch]);

  useEffect(() => {
    dispatch(setSelectedPlan(selectedPlan));
  }, [dispatch, selectedPlan]);

  const handleTabSelection = (event: any, selectedTabIndex: any) => {
    setCurrentTabIndex(selectedTabIndex);
  };

  const handleSelectedPlan = (plan: any) => {
    // if the user is signed in, mark the plan as selected.  Otherwise, send them to sign in page with a way to get back here.
    if (isUserSignedIn) {
      setSelectedPlanId(plan.id);
    } else {
      const redirectUrlString = `/plans?${location.search}`;
      const redirectUri = encodeURIComponent(redirectUrlString);
      history.push(`/login?redirectUri=${redirectUri}`);
    }
  };

  const getMonthlyPlans = () => {
    let monthlyPlans: Plan[] = [];
    if (plans.length > 0) {
      plans.forEach((plan: any) => {
        if (
          isMonthlyPlan(plan, brand) &&
          cardBlackList.indexOf(plan.external_plan_code) === -1 &&
          !isLegacyPlan(plan)
        ) {
          monthlyPlans.push(plan);
        }
      });
    }

    return monthlyPlans.filter(filterPlansByCurrency).sort(sortByPrice);
  };

  const getYearlyPlans = () => {
    let YearlyPlans: Plan[] = [];
    if (plans.length > 0) {
      plans.forEach((plan: any) => {
        if (isYearlyPlan(plan) && cardBlackList.indexOf(plan.external_plan_code) === -1 && !isLegacyPlan(plan)) {
          YearlyPlans.push(plan);
        }
      });
    }

    return YearlyPlans.filter(filterPlansByCurrency).sort(sortByPrice);
  };

  const getTwoYearPlans = () => {
    let twoYearPlans: Plan[] = [];
    if (plans.length > 0) {
      plans.forEach((plan: any) => {
        if (isTwoYearPlan(plan) && cardBlackList.indexOf(plan.external_plan_code) === -1 && !isLegacyPlan(plan)) {
          twoYearPlans.push(plan);
        }
      });
    }

    return twoYearPlans.filter(filterPlansByCurrency).sort(sortByPrice);
  };

  /**
   * With Stripe, prices for different currencies are part of different plan_codes, so we need to make sure that we are
   * only showing plans that have the expected currency.
   * @param plan
   */
  const filterPlansByCurrency = (plan: Plan) => {
    return plan.amountInCents?.hasOwnProperty(currency);
  };

  const sortByPrice = (plan_a: Plan, plan_b: Plan) => {
    const a = plan_a.amountInCents?.[currency];
    const b = plan_b.amountInCents?.[currency];
    if (a && b) {
      return (parseFloat(a._) || 0) - (parseFloat(b._) || 0);
    }
    return 0;
  };

  const getYearlyTabText = () => {
    if (brand === brandNames.visi) {
      return <FormattedMessage {...messages.yearlyTab} />;
    }

    return <FormattedMessage {...messages.yearlyTabBestValue} />;
  };

  return isLoadingIndicatorVisible ? (
    <div className="progress-wrapper-center">
      <CircularProgress size={55} className={`${brand}-button-progress`} />
    </div>
  ) : (
    <>
      <Paper className={`${brand}-app-bar-secondary`}>
        <Tabs
          value={currentTabIndex}
          onChange={handleTabSelection}
          centered
          variant="fullWidth"
          className={`${brand}-tabs-secondary`}
          TabIndicatorProps={{
            style: {
              backgroundColor: '#fff',
              paddingTop: 2,
            },
          }}>
          <Tab className={`${brand}-tab-secondary`} label={<FormattedMessage id="monthlyTab" />} id="monthly-tab" />
          {getYearlyPlans().length > 0 ? (
            <Tab className={`${brand}-tab-secondary`} label={getYearlyTabText()} id="yearly-tab" />
          ) : null}
          {brand === brandNames.visi ? (
            <Tab
              className={`${brand}-tab-secondary`}
              label={<FormattedMessage {...messages.twoYearTab} />}
              id="2-year-tab"
            />
          ) : null}
        </Tabs>
      </Paper>
      <TabPanel brand={brand} value={currentTabIndex} index={MONTHLY_PLANS_TAB_INDEX} id="tab-0">
        <TabControl
          brand={brand}
          plans={getMonthlyPlans()}
          handleSelectedPlan={handleSelectedPlan}
          selectedPlan={selectedPlan}
          currentPlan={currentPlan}
          payFrequency="per month"
          eligiblePlans={eligiblePlans}
          isUserSignedIn={isUserSignedIn}></TabControl>
      </TabPanel>
      <TabPanel brand={brand} value={currentTabIndex} index={YEARLY_PLANS_TAB_INDEX} id="tab-1">
        <TabControl
          brand={brand}
          plans={getYearlyPlans()}
          handleSelectedPlan={handleSelectedPlan}
          selectedPlan={selectedPlan}
          currentPlan={currentPlan}
          payFrequency="per year"
          eligiblePlans={eligiblePlans}
          isUserSignedIn={isUserSignedIn}></TabControl>
      </TabPanel>
      {brand === brandNames.visi ? (
        <TabPanel brand={brand} value={currentTabIndex} index={TWO_YEAR_PLANS_TAB_INDEX} id="tab-2">
          <TabControl
            brand={brand}
            plans={getTwoYearPlans()}
            handleSelectedPlan={handleSelectedPlan}
            selectedPlan={selectedPlan}
            currentPlan={currentPlan}
            payFrequency="per 2 years"
            eligiblePlans={eligiblePlans}
            isUserSignedIn={isUserSignedIn}></TabControl>
        </TabPanel>
      ) : null}
      <PlanActionControl
        visible={isUserSignedIn}
        brand={brand}
        currentPlan={currentPlan}
        selectedPlan={selectedPlan}></PlanActionControl>
    </>
  );
};
export default PlanListPage;
