import { defineMessages } from 'react-intl';

export default defineMessages({
  deleteAccountTitle: {
    id: 'accountManagement.components.deleteAccount.title.deleteAccount',
    defaultMessage: 'There is a pending delete account request on your account.',
  },
  deleteAccountSubtext: {
    id: 'accountManagement.components.deleteAccount.subtext.deleteAccount',
    defaultMessage: 'You can’t sign in to your account unless the delete account request is canceled.',
  },
  deleteAccountPrimaryBtn: {
    id: 'accountManagement.components.deleteAccount.PrimaryBtn:.deleteAccount',
    defaultMessage: 'Cancel Request',
  },
  deleteAccountSecondaryBtn: {
    id: 'accountManagement.components.deleteAccount.SecondaryBtn.deleteAccount',
    defaultMessage: 'Keep Request',
  },
  deleteAccountErrorTitle: {
    id: 'accountManagement.components.deleteAccountError.title.deleteAccount',
    defaultMessage: 'Sorry, we are unable to cancel your delete account request at this time.',
  },
  deleteAccountErrorSubtext: {
    id: 'accountManagement.components.deleteAccountError.subtext.deleteAccount',
    defaultMessage: 'Please contact customer support for assistance.',
  },
  deleteAccountPrimaryErrorBtn: {
    id: 'accountManagement.components.deleteAccountError.PrimaryBtn:.deleteAccount',
    defaultMessage: 'Email Support',
  },
  deleteAccountSecondaryErrorBtn: {
    id: 'accountManagement.components.deleteAccountError.SecondaryBtn.deleteAccount',
    defaultMessage: 'Close',
  },
});
