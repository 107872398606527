/*
 * Header Messages
 *
 * This contains all the text for the Header component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  signIn: {
    id: 'accountManagement.components.header.button.signIn.text',
    defaultMessage: 'Sign In',
  },
  signOut: {
    id: 'accountManagement.components.header.button.signOut.text',
    defaultMessage: 'Sign Out',
  }
});