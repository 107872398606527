import {
  AUTHENTICATION_ATTEMPT,
  AUTHENTICATION_FAILURE,
  AUTHENTICATION_NEEDS_MFA,
  AUTHENTICATION_PATCH_PROFILE,
  AUTHENTICATION_SUCCESS,
  CLEAR_ERROR,
  CLEAR_MFA_STATE,
  CLEAR_STATE,
  MFA_RESEND_VERIFICATION_ATTEMPT,
  MFA_RESEND_VERIFICATION_FAILURE,
  MFA_RESEND_VERIFICATION_SUCCESS,
  MFA_VERIFICATION_ATTEMPT,
  MFA_VERIFICATION_FAILURE,
  MFA_VERIFICATION_SUCCESS,
  REFRESH_TOKEN,
  SIGN_OUT_INITIATED,
  SIGN_OUT_SUCCESSFUL,
  SOCIAL_AUTHENTICATION_ATTEMPT,
  SOCIAL_AUTHENTICATION_FAILURE,
  SOCIAL_AUTHENTICATION_SUCCESS,
  SSO_ATTEMPT,
  SSO_FAILURE,
  SSO_SUCCESS,
  SET_IS_OAUTH_LOGIN,
  TRIGGER_MISSING_PARAMETERS_ERROR,
  SET_ACCOUNT_LINKING_PARTNER,
  SET_OAUTH_PARAMETERS,
} from './actionTypes';
import {IUserInfo, LoginActionTypes, MfaData, OAuthParameters, UserMFASettings} from './types';

export const authenticationAttempt = (userInfo: IUserInfo, redirectUri?: string): LoginActionTypes => ({
  type: AUTHENTICATION_ATTEMPT,
  userInfo,
  payload: { redirectUri },
});

export const authenticationSuccess = (username: string, profile: any): LoginActionTypes => ({
  type: AUTHENTICATION_SUCCESS,
  username,
  profile,
});

export const authenticationFailure = (errorCode: any): LoginActionTypes => ({
  type: AUTHENTICATION_FAILURE,
  errorCode,
});

export const initiateSignOut = (): LoginActionTypes => ({
  type: SIGN_OUT_INITIATED,
});

export const successfulSignOut = (): LoginActionTypes => ({
  type: SIGN_OUT_SUCCESSFUL,
});

export const ssoAttempt = (token: any): LoginActionTypes => ({
  type: SSO_ATTEMPT,
  token,
});

export const ssoSuccess = (username: string | undefined, profile: any): LoginActionTypes => ({
  type: SSO_SUCCESS,
  username,
  profile,
});

export const ssoFailure = (errorCode: any): LoginActionTypes => ({
  type: SSO_FAILURE,
  errorCode,
});

export const clearState = (): LoginActionTypes => ({
  type: CLEAR_STATE,
});

export const clearMfaState = (): LoginActionTypes => ({
  type: CLEAR_MFA_STATE,
});

export const clearError = (): LoginActionTypes => ({
  type: CLEAR_ERROR,
});

export const patchProfile = (profile: any): LoginActionTypes => ({
  type: AUTHENTICATION_PATCH_PROFILE,
  profile,
});

export const refreshToken = (token: string): LoginActionTypes => ({
  type: REFRESH_TOKEN,
  token,
});

export const socialAuthenticationAttempt = (payload: {
  socialProvider: 'appleweb' | 'google';
  socialCredential: {
    idToken?: string;
    authCode?: string;
  };
  redirectUri?: string;
}): LoginActionTypes => ({
  type: SOCIAL_AUTHENTICATION_ATTEMPT,
  payload,
});

export const socialAuthenticationSuccess = (payload: { profile: any }): LoginActionTypes => ({
  type: SOCIAL_AUTHENTICATION_SUCCESS,
  payload,
});

export const socialAuthenticationFailure = (): LoginActionTypes => ({ type: SOCIAL_AUTHENTICATION_FAILURE });

export const authenticationNeedsMfa = (mfaData: MfaData, userMFASettings?: UserMFASettings): LoginActionTypes => ({
  type: AUTHENTICATION_NEEDS_MFA,
  mfaData,
  userMFASettings
});
export const mfaVerificationAttempt = (code: string, redirectUri?: string): LoginActionTypes => ({
  type: MFA_VERIFICATION_ATTEMPT,
  code,
  payload: { redirectUri },
});
export const mfaVerificationSuccess = (username: string, profile: any): LoginActionTypes => ({
  type: MFA_VERIFICATION_SUCCESS,
  username,
  profile,
});
export const mfaVerificationFailure = (errorCode: any): LoginActionTypes => ({
  type: MFA_VERIFICATION_FAILURE,
  errorCode,
});
export const mfaResendVerificationAttempt = (): LoginActionTypes => ({ type: MFA_RESEND_VERIFICATION_ATTEMPT });
export const mfaResendVerificationSuccess = (results: { success: boolean }): LoginActionTypes => ({
  type: MFA_RESEND_VERIFICATION_SUCCESS,
  results,
});
export const mfaResendVerificationFailure = (errorCode: any): LoginActionTypes => ({
  type: MFA_RESEND_VERIFICATION_FAILURE,
  errorCode,
});

export const setIsOAuthLogin = (isOAuthLogin: boolean): LoginActionTypes => ({
  type: SET_IS_OAUTH_LOGIN,
  isOAuthLogin,
});

export const setAccountLinkingPartner = (partner: string): LoginActionTypes => ({
  type: SET_ACCOUNT_LINKING_PARTNER,
  partner,
});

export const triggerMissingParametersError = (): LoginActionTypes => ({
  type: TRIGGER_MISSING_PARAMETERS_ERROR,
  errorCode: 'missing_parameters',
});

export const setOAuthParameters = (params: OAuthParameters): LoginActionTypes => ({
  type: SET_OAUTH_PARAMETERS,
  params,
});
