import React from 'react';

//material ui
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

//redux
import { getBrandImagesSelector } from '../BrandProvider/selectors';
import { useSelector } from 'react-redux';

//third party lib
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from './messages';

interface Props {
  open: boolean;
  handleClose: Function;
  confirmExit: Function;
  brand: string;
  intl: any;
}

const ConfirmExitModal = (props: Props) => {
  const { open, brand } = props;
  const images = useSelector(getBrandImagesSelector);
  const { formatMessage } = props.intl;

  const handleClose = () => {
    props.handleClose();
  };

  const confirmExit = () => {
    props.confirmExit();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <img
            src={images[`${brand}-alert-circle`] ? images[`${brand}-alert-circle`] : null}
            alt={formatMessage(messages.warningAltText)}
            className={`${brand}-confirm-modal-icon`}
          />
        </DialogTitle>
        <DialogTitle id="alert-dialog-title" style={{ fontWeight: 'bold' }}>
          <FormattedMessage {...messages.modalTitle} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormattedMessage {...messages.confirmExitStatement} />
          </DialogContentText>
        </DialogContent>
        <DialogActions id="alert-dialog-actions">
          <Button
            onClick={handleClose}
            className={`${brand}-btn-text`}
            style={{ textTransform: 'none' }}
            id="cancel-but"
          >
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button
            onClick={confirmExit}
            className={`${brand}-btn ${brand}-btn-action `}
            style={{
              width: 165,
              textTransform: 'none',
              height: 42,
              fontWeight: 'bolder',
            }}
            autoFocus
            id="exit-but"
          >
            <FormattedMessage {...messages.exitButton} />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default injectIntl(ConfirmExitModal);
