import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { getAppUrls, getSocialConfig } from '../../env/config_util';
import { logEvent } from '../../utils/analytics/analyticsLogger';
import { AnalyticsEvent } from '../../utils/analytics/events';
import { getBrandImagesSelector, getBrandNameSelector } from '../BrandProvider/selectors';
import { getPrevPathSelector } from '../PathName/selectors';
import {
  authenticationAttempt,
  clearError,
  clearState,
  setAccountLinkingPartner,
  setIsOAuthLogin,
  setOAuthParameters,
  socialAuthenticationAttempt,
  ssoAttempt,
  triggerMissingParametersError,
} from './actions';
import { LoginAppleButton } from './loginAppleButton';
import { LoginGoogleButton } from './loginGoogleButton';
import messages from './messages';
import {
  getAccountLinkingPartnerSelector,
  getAttemptingSsoSelector,
  getErrorSelector,
  getIsAttemptingSelector,
  getIsOAuthLoginSelector,
  getLoginErrorMessage,
  getProfileSelector,
} from './selectors';
import { IUserInfo } from './types';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '90%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  divider: {
    display: 'flex',
    alignItems: 'center',
    margin: '30px 0',
  },
  dividerLine: {
    flex: 1,
    height: 1,
    backgroundColor: theme.palette.grey[400],
  },
  dividerLineLeft: {
    marginRight: theme.spacing(4),
  },
  dividerLineRight: {
    marginLeft: theme.spacing(4),
  },
  socialContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  appLinkContainer: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  appLinkImage: {
    width: '100%',
  },
  appLinkImageIos: {
    padding: '10%',
  },
}));

interface LoginPageProps extends RouteComponentProps {}

function LoginPage(props: LoginPageProps) {
  const { formatMessage } = useIntl();
  //redux selectors
  const dispatch = useDispatch();
  const classes = useStyles();
  const brand = useSelector(getBrandNameSelector);
  const images = useSelector(getBrandImagesSelector);
  const loading = useSelector(getIsAttemptingSelector);
  const error = useSelector(getErrorSelector);
  const errorMessage = useSelector(getLoginErrorMessage);
  const ssoAttempting = useSelector(getAttemptingSsoSelector);
  const isOAuthLogin = useSelector(getIsOAuthLoginSelector);
  const accountLinkingPartner = useSelector(getAccountLinkingPartnerSelector);
  const [showPassword, setShowPassword] = useState(false);
  const redirectUri = React.useMemo(() => {
    const searchParams = new URLSearchParams(props.location.search.substring(1));
    const uri = searchParams.get('redirectUri');
    return uri ? window.decodeURIComponent(uri) : undefined;
  }, [props.location.search]);

  // An effect that completes the apple web auth flow if we have landed on this page with an `lr-token` hash param
  useEffect(() => {
    if (props.location.hash) {
      const hashParams = new URLSearchParams(props.location.hash.substring(1));
      const lrToken = hashParams.get('lr-token');

      if (lrToken) {
        dispatch(
          socialAuthenticationAttempt({
            socialProvider: 'appleweb',
            socialCredential: { authCode: lrToken },
            redirectUri,
          }),
        );
      }
    }
  }, [dispatch, props.location.hash, redirectUri]);

  const socialConfigs = React.useMemo(() => {
    const c = getSocialConfig(brand);
    if (!c) return undefined;
    return { apple: !!c.apple, google: !!c.google };
  }, [brand]);

  const appUrls = React.useMemo<{ ios?: string; android?: string } | undefined>(() => {
    const c = getAppUrls(brand);
    return c;
  }, [brand]);

  // There is a requirement to log an analytics event when the user successfully
  // signs out. The "Sign Out" link simply redirects to this component and so
  // success in this case is this page getting rendered.  Functions passed to
  // useEffect() will get invoked after the page is rendered so the invocation
  // of the function implies success.  The issue is how to determine whether the
  // user signed out or is coming to the page for the first time.  If prevPath
  // is undefined then we did not come here from an application page.  We'll
  // want to pass the profile to logEvent, and based on testing it appears best
  // if we check not just prevPath but also the profile.
  const prevPath = useSelector(getPrevPathSelector);
  const profile = useSelector(getProfileSelector);
  useEffect(() => {
    if (prevPath && profile) {
      logEvent(AnalyticsEvent.ACCOUNT_SIGN_OUT, profile);
    }
  }, [prevPath, profile]);

  //state
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember] = useState(false);

  const signInDisabled = loading || !email || !password;

  useEffect(() => {
    const clearStateInvoke = () => {
      dispatch(clearState());
      dispatch(clearError());

      if (props.location?.pathname) {
        const pathSegments = props.location.pathname.split('/');
        if (pathSegments.length >= 2 && pathSegments[1] === 'oauth') {
          dispatch(setIsOAuthLogin(true));
          if (props.location.search === '') {
            dispatch(triggerMissingParametersError());
          }
          if (pathSegments[pathSegments.length - 1] !== 'oauth') {
            dispatch(setAccountLinkingPartner(pathSegments[pathSegments.length - 1]));
          }
        } else {
          if (isOAuthLogin) dispatch(setIsOAuthLogin(false));
        }
      }

      if (props.location?.search !== '') {
        const query = queryString.parse(props.location.search);
        if (query.token) {
          dispatch(ssoAttempt(query.token));
        }

        if (isOAuthLogin) {
          dispatch(
            setOAuthParameters({
              clientId: query.client_id as string,
              redirectUri: query.redirect_uri as string,
              state: query.state as string,
            }),
          );

          if (!query.client_id || !query.redirect_uri || !query.state) {
            dispatch(triggerMissingParametersError());
          }
        }
      }
    };
    clearStateInvoke();
  }, [dispatch, props.location, isOAuthLogin]);

  useEffect(() => {
    const hasError = () => {
      if (error) {
        setOpen(true);
      }
    };
    hasError();
  }, [error]);

  
  const handleSubmit = (e: any) => {
    let userInfo: IUserInfo = {
      username: email,
      password,
      rememberMe: remember,
    };

    e.preventDefault();
    dispatch(authenticationAttempt(userInfo, redirectUri));
  };

  const getOAuthMessage = () => {
    switch (accountLinkingPartner) {
      case 'google':
        return <FormattedMessage {...messages.googleAuthMessage} />;
      case 'alexa':
        return <FormattedMessage {...messages.alexaAuthMessage} />;
      default:
        return <div />;
    }
  };
  
  
  // Disables "floating label" functionality for night owl inputs. If the prop is provided with either true or false
  // the float is disabled, so we don't even need to provide the prop at all if the brand isn't night owl. MUI could
  // solve this with a "default" shrink behavior value, but that doesn't exist.
  let optionalNightOwlInputProps = {};
  if (brand === 'nightowl') {
    optionalNightOwlInputProps = {
     InputLabelProps:{ 
       shrink: true 
     }
    }
  }

  const loginForm = (
    <>
      <div className={`${classes.paper} ${brand}-login-paper`} style={{ width: '100%' }}>
        <Collapse in={open && error} style={{ width: '100%', marginTop: 10 }}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
                id="error-alert">
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {open ? <FormattedMessage {...errorMessage} /> : ''}
          </Alert>
        </Collapse>
        <div>
          <img src={images['logo-login']} alt="Logo" className={`${brand}-login-logo`} />
        </div>

        {getOAuthMessage()}

        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            { ...optionalNightOwlInputProps }
            className={`${brand}-text-field`}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={<FormattedMessage {...messages.username} />}
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e: any) => {
              setEmail(e.target.value);
            }}
            autoFocus
          />
          
          <div style={{ position: 'relative' }}>
            {brand === 'nightowl' && password.length ? (
              <button 
                className={`${brand}-show-hide-btn`} 
                onClick={(e) => {
                  e.preventDefault();
                  setShowPassword(!showPassword);
                }}
              >{showPassword ? 'Hide' : 'Show'}</button>
            ) : null}
            <TextField
              { ...optionalNightOwlInputProps }
              className={`${brand}-text-field`}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={<FormattedMessage {...messages.password} />}
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e: any) => {
                setPassword(e.target.value);
              }}
              id="password"
              autoComplete="password"
            />
          </div>
          {brand === 'nightowl' ? (
            <Link to="/forgot-password" className={`${brand}-forgot-password-link`}>
              Forgot Password?
            </Link>
          ) : null}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={signInDisabled ? `${brand}-login-button-disabled` : `${brand}-login-button`}
            id="signIn"
            style={{
              textTransform: 'none',
              fontWeight: 'bolder',
              fontSize: 'larger',
              marginTop: 24
            }}
            disabled={signInDisabled}>
            <FormattedMessage {...messages.signIn} />
          </Button>
          {loading && (
            <div className="progress-wrapper">
              <CircularProgress size={34} className={`${brand}-button-progress`} />
            </div>
          )}
        </form>
      </div>
      {socialConfigs && (
        <>
          <div className={classes.divider}>
            <div className={[classes.dividerLine, classes.dividerLineLeft].join(' ')} />
            <div>
              <FormattedMessage {...messages.dividerOr} />
            </div>
            <div className={[classes.dividerLine, classes.dividerLineRight].join(' ')} />
          </div>
          <Grid
            container
            justify="space-around"
            alignItems="center"
            spacing={2}
            className={classes.socialContainer}>
            {socialConfigs.google && (
              <Grid item container xs={12} sm={6} justify="center">
                <LoginGoogleButton redirectUri={redirectUri} />
              </Grid>
            )}
            {socialConfigs.apple && (
              <Grid item container xs={12} sm={6} justify="center">
                <LoginAppleButton redirectUri={redirectUri} />
              </Grid>
            )}
          </Grid>
        </>
      )}
    </>
  );

  return (
    <>
      <Helmet titleTemplate="Account Management - %s" defaultTitle="Login">
        <meta name="description" content="Account Management" />
      </Helmet>
      {ssoAttempting ? (
        <div className="progress-wrapper-center">
          <CircularProgress size={55} className={`${brand}-button-progress`} />
        </div>
      ) : (
        <Container className={`${brand}-screen-bg`}>
          <Container className={`${brand}-bg login_container`} component="main" maxWidth="xs">
            <CssBaseline />
            {loginForm}
            {appUrls && (
              <div className={classes.appLinkContainer}>
                <div className={classes.divider}>
                  <div className={[classes.dividerLine, classes.dividerLineLeft].join(' ')} />
                  <div>
                    <FormattedMessage {...messages.getAppPrompt} />
                  </div>
                  <div className={[classes.dividerLine, classes.dividerLineRight].join(' ')} />
                </div>
                <Grid container justify="space-around">
                  {appUrls.android && (
                    <Grid item xs={6}>
                      <a href={appUrls.android}>
                        <img
                          alt={formatMessage(messages.getAppAndroid)}
                          src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                          className={classes.appLinkImage}
                          style={{ width: '100%' }}
                        />
                      </a>
                    </Grid>
                  )}
                  {appUrls.ios && (
                    <Grid item xs={6}>
                      <a href={appUrls.ios}>
                        <img
                          alt={formatMessage(messages.getAppIOS)}
                          src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                          className={[classes.appLinkImage, classes.appLinkImageIos].join(' ')}
                        />
                      </a>
                    </Grid>
                  )}
                </Grid>
              </div>
            )}
          </Container>
        </Container>
      )}
    </>
  );
}

export default withRouter(LoginPage);
