import React, { useEffect } from 'react';

//redux
import { getSelectedSubscriptionSelector } from '../SubscriptionsList/selectors';
import { getDevicesToManageSelector } from '../ManageDevices/selectors';
import { setCurrentAndSelectedPlan } from './actions';
import { useDispatch, useSelector } from 'react-redux';
import { getBrandImagesSelector } from '../BrandProvider/selectors';
import { setDevicesAsAssigned } from '../ManageDevices/actions';
import {
  getCheckoutSessionFailureSelector,
  getCheckoutSessionUrlSelector,
  getCreatingCheckoutSessionSelector,
  getRedirectingToCheckoutSessionSelector,
} from '../../store/CheckoutSession/selectors';
import { clearCheckoutSessionState, createCheckoutSessionAttempt } from '../../store/CheckoutSession/actions';

import { getBillingInformationExistsSelector, getCurrencySelector } from '../BillingInformationView/selectors';

//third party libs
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import messages from './messages';

//material ui
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

//models
import ChangeSubscriptionConfirmation from '../ChangeSubscriptionConfirmation';
import CurrencyFormatter from '../../components/Formatters/CurrencyFormatter';
import ConfirmExitModal from '../ConfirmExitModal';
import history from '../../history';
import DateFormatter from '../../components/Formatters/DateFormatter';

//constants
import { freePlansCodes } from '../../constants/freePlans';
import { brandNames } from '../../constants/brandNames';
import { isNewCheckoutSessionSelector } from './selectors';
import LoadingModel from '../LoadingModel';
import { logEvent } from '../../utils/analytics/analyticsLogger';
import { getProfileSelector } from '../LoginPage/selectors';
import { AnalyticsEvent } from '../../utils/analytics/events';
import { Plan } from './types';
import { setPrevPathName } from '../PathName/actions';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    position: 'fixed',
    bottom: 55,
    width: '100%',
    '& > *': {
      width: '100%',
      height: theme.spacing(12),
    },
  },
}));

interface Props {
  brand: string;
  currentPlan?: Plan;
  selectedPlan?: Plan;
  visible: boolean;
}

const PlanActionControl = (props: Props) => {
  const classes = useStyles();
  const { brand, currentPlan, selectedPlan, visible } = props;
  const isMobile = useMediaQuery({ query: `(max-width: 600px)` });
  const currency = useSelector(getCurrencySelector);
  const userProfile = useSelector(getProfileSelector);
  const images = useSelector(getBrandImagesSelector);
  const billingExists = useSelector(getBillingInformationExistsSelector);
  const devices = useSelector(getDevicesToManageSelector);
  const [open, setOpen] = React.useState(false);
  const [openChangeSubModel, setOpenChangeSubModel] = React.useState(false);
  const [openChangeSubModelFree, setOpenChangeSubModelFree] = React.useState(false);
  const isLegacyPlanCurrent = currentPlan?.legacy;
  const isLegacyPlanSelected = selectedPlan?.legacy;

  const currentSub = useSelector(getSelectedSubscriptionSelector);

  const dispatch = useDispatch();
  const isNewCheckoutSession = useSelector(isNewCheckoutSessionSelector);
  const checkoutSessionUrl = useSelector(getCheckoutSessionUrlSelector);
  useEffect(() => {
    if (checkoutSessionUrl) {
      let url = checkoutSessionUrl;
      dispatch(clearCheckoutSessionState());
      window.location.replace(url);
    }
  }, [checkoutSessionUrl, dispatch]);

  const checkoutSessionFailure = useSelector(getCheckoutSessionFailureSelector);
  useEffect(() => {
    if (checkoutSessionFailure) {
      dispatch(clearCheckoutSessionState());
      history.push('/error');
    }
  }, [checkoutSessionFailure, dispatch]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getPriceText = () => {
    if (currentPlan && currentPlan.amountInCents && currentPlan.amountInCents[currency]) {
      return <CurrencyFormatter value={currentPlan.amountInCents[currency]?._} />;
    } // FIXME: if the user's current Subscription doesn't match their configured currency, we will show '$0.00'

    return <CurrencyFormatter value={0} />;
  };

  const getBillingCycle = () => {
    if (currentPlan && currentPlan.billing_cycle && currentPlan.billing_cycle < 12) {
      return <FormattedMessage {...messages.perMonth} />;
    } else if (currentPlan && currentPlan.billing_cycle && currentPlan.billing_cycle >= 12) {
      return <FormattedMessage {...messages.perYear} />;
    }
  };

  const handleChangePlan = () => {
    dispatch(setCurrentAndSelectedPlan(currentPlan, selectedPlan));
    dispatch(setPrevPathName('/plans'));

    if (
      currentPlan &&
      selectedPlan &&
      currentPlan.billing_order &&
      selectedPlan.billing_order &&
      selectedPlan.billing_order < currentPlan.billing_order &&
      brand !== brandNames.visi
    ) {
      setOpenChangeSubModel(true);
    } else if (
      currentPlan &&
      selectedPlan &&
      currentPlan.billing_order &&
      selectedPlan.billing_order &&
      selectedPlan.billing_order < currentPlan.billing_order &&
      brand === brandNames.visi &&
      selectedPlan.name !== currentPlan.name
    ) {
      setOpenChangeSubModel(true);
    } else {
      confirmChangeSub();
    }

    const selectedPlanEventName = `AcctDashSelect${selectedPlan?.name}Plan`;
    logEvent(selectedPlanEventName, userProfile);
  };

  const confirmChangeSub = () => {
    if (!selectedPlan) {
      history.push('/manage-devices');
      return;
    }

    if (freePlansCodes.indexOf(selectedPlan.external_plan_code) !== -1) {
      selectedPlanIsFree();
    } else {
      // if the selected plan only includes one device (known as the BASIC PLAN for Geeni)
      if (selectedPlan.device_quantity === 1) {
        let devicesOnCurrentPlan = [];
        if (currentSub && currentSub.devices.length > 0) {
          devicesOnCurrentPlan = currentSub.devices;
        }
        // if you already have more than one device subscribed to a plan, pick which ones to move to the new plan
        if (devicesOnCurrentPlan.length > 1) {
          history.push('/manage-devices');
        } else {
          // otherwise move the one existing device (if there is one)
          dispatch(setDevicesAsAssigned(devicesOnCurrentPlan, []));
          if (isNewCheckoutSession) {
            dispatch(createCheckoutSessionAttempt());
          } else {
            if (billingExists) {
              history.push('/order-summary');
            } else {
              history.push('/billing');
            }
          }
        }
        // all ofther plans
      } else if (devices && devices.length > 0) {
        if (devices.length <= selectedPlan.device_quantity) {
          dispatch(setDevicesAsAssigned(devices, []));
          if (isNewCheckoutSession) {
            dispatch(createCheckoutSessionAttempt());
          } else {
            if (billingExists) {
              history.push('/order-summary');
            } else {
              history.push('/billing');
            }
          }
        } else {
          history.push('/manage-devices');
        }
      } else {
        history.push('/manage-devices');
      }
    }
  };

  const selectedPlanIsFree = () => {
    setOpenChangeSubModelFree(true);
  };

  const confirmChangeSubFree = () => {
    dispatch(setDevicesAsAssigned(currentSub.devices, []));
    if (isNewCheckoutSession) {
      dispatch(createCheckoutSessionAttempt());
    } else {
      if (billingExists) {
        history.push('/order-summary');
      } else {
        history.push('/billing');
      }
    }
  };
  const handleCloseChangeSubModel = () => {
    setOpenChangeSubModel(false);
    setOpenChangeSubModelFree(false);
  };

  const getBackButton = () => {
    if (isLegacyPlanCurrent && isLegacyPlanSelected) {
      return (
        <Button
          className={`${brand}-btn-text`}
          style={{ textTransform: 'none', fontSize: 'larger', marginRight: 64 }}
          onClick={handleOpen}
          id="legacy-exit-btn">
          <FormattedMessage {...messages.backBtn} />
        </Button>
      );
    }
    return (
      <Button className={`${brand}-btn-text`} style={{ textTransform: 'none' }} onClick={handleOpen} id="exit-btn">
        <FormattedMessage {...messages.backBtn} />
      </Button>
    );
  };

  const getActionButton = () => {
    if (isLegacyPlanCurrent && isLegacyPlanSelected) {
      return (
        <Button
          className={`${brand}-primary`}
          disabled={true}
          style={{ textTransform: 'none', fontSize: 'larger' }}
          id="next-btn">
          {images['selected-plan-icon'] ? (
            <img src={images['selected-plan-icon']} alt="icon" className={`${brand}-card-icon`} />
          ) : null}
          <FormattedMessage {...messages.currentPlan} />
        </Button>
      );
    }

    if (props.currentPlan && props.selectedPlan && props.currentPlan.id === props.selectedPlan.id) {
      return (
        <Button
          className={`${brand}-primary`}
          disabled={currentPlan?.id === selectedPlan?.id}
          style={{ textTransform: 'none', fontSize: 'larger' }}
          onClick={handleChangePlan}
          id="next-btn">
          {images['selected-plan-icon'] ? (
            <img src={images['selected-plan-icon']} alt="icon" className={`${brand}-card-icon`} />
          ) : null}
          <FormattedMessage {...messages.currentPlan} />
        </Button>
      );
    }

    const isDisabled = currentPlan === selectedPlan || !selectedPlan;
    return (
      <Button
        className={`${brand}-btn ${brand}-btn-action ${brand}-btn-card ${
          isDisabled ? brand + '-btn-action-disabled' : ''
        }`}
        disabled={isDisabled}
        style={{ textTransform: 'none' }}
        onClick={handleChangePlan}
        id="next-btn">
        <FormattedMessage {...messages.changePlan} />
      </Button>
    );
  };

  const getPlanName = () => {
    if (currentPlan && currentPlan.name) {
      return currentPlan.name;
    }

    return null;
  };

  const confirmExit = () => {
    history.goBack();
    logEvent(AnalyticsEvent.ACCTDASH_BACK_CONFIRM, userProfile);
  };

  let isRedirectingToCheckoutSession = useSelector(getRedirectingToCheckoutSessionSelector);
  let creatingCheckoutSession = useSelector(getCreatingCheckoutSessionSelector);

  return !visible ? null : (
    <>
      <div style={{ height: 70 }}>
        <div className={`${classes.root}`}>
          <Paper className={`${brand}-plan-action-control-wrapper`} variant="outlined" square>
            <div className={`${brand}-plan-action-control`}>
              <div>
                <Typography variant="caption" display="block" gutterBottom>
                  <FormattedMessage {...messages.currentPlan} />
                </Typography>
                <Typography
                  variant={isMobile ? 'h6' : 'h5'}
                  gutterBottom
                  style={{ fontWeight: 'bold', lineHeight: 0.6 }}>
                  {getPlanName()} <FormattedMessage {...messages.planText} />
                </Typography>
                <Typography style={{ marginBottom: 4 }}>
                  {getPriceText()} {getBillingCycle()}
                </Typography>
              </div>
              <div className={`${brand}-plan-action-control-buttons`}>
                {getBackButton()}
                {getActionButton()}
              </div>
            </div>
          </Paper>
        </div>
        <ConfirmExitModal
          open={open}
          confirmExit={confirmExit}
          handleClose={handleClose}
          brand={brand}></ConfirmExitModal>
        <ChangeSubscriptionConfirmation
          open={openChangeSubModel || openChangeSubModelFree}
          confirmChangeSubscription={openChangeSubModelFree ? confirmChangeSubFree : confirmChangeSub}
          handleClose={handleCloseChangeSubModel}
          brand={brand}
          plan={currentPlan}
          expireDate={
            currentSub && currentSub.recurly_period_end_date ? (
              <DateFormatter date={currentSub.recurly_period_end_date} />
            ) : null
          }
          devices={currentSub && currentSub.devices ? currentSub.devices : []}
          external_plan_code={currentPlan ? currentPlan.external_plan_code : ''}
          selectedPlan={selectedPlan}></ChangeSubscriptionConfirmation>
        <LoadingModel
          open={creatingCheckoutSession || isRedirectingToCheckoutSession}
          brand={brand}
          title={<FormattedMessage {...messages.redirecting} />}></LoadingModel>
      </div>
    </>
  );
};

export default PlanActionControl;
