import React from 'react';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import { Helmet } from 'react-helmet-async';

import { useSelector } from 'react-redux';
import { getBrandNameSelector } from '../../BrandProvider/selectors';

interface Props {
  children: any;
  pageName: string;
}

const FlowLayout = (props: Props) => {
  const brand = useSelector(getBrandNameSelector);
  return (
    <div className={`body-bg ${brand}-body ${brand}-font`}>
      <div className="main-layout">
        <Helmet titleTemplate="%s - Account Management" defaultTitle="Account Management">
          <meta name="description" content="Account Management" />
        </Helmet>
        <Header pageName={props.pageName}></Header>
        <main role="main" className="w-100 h-100">
          {React.Children.toArray(props.children)}
        </main>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default FlowLayout;
