import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';

//redux
import { getBrandNameSelector, getBrandImagesSelector } from '../../containers/BrandProvider/selectors';
import { getIsAuthenticatedSelector, getProfileSelector } from '../../containers/LoginPage/selectors';
import { useSelector } from 'react-redux';

import SignOut from "./SignOut";
import SignIn from "./SignIn";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    height: 70,
    padding: 10,
    marginBottom: 'auto',
    marginTop: 'auto',
  },
}));

interface Props {
  pageName?: string;
}

const Header = (props: Props) => {
  const classes = useStyles();
  const brand = useSelector(getBrandNameSelector);
  const images = useSelector(getBrandImagesSelector);
  const profile = useSelector(getProfileSelector);
  const isUserAuthenticated = useSelector(getIsAuthenticatedSelector);
  const isMobile = useMediaQuery({ query: `(max-width: 600px)` });
  
  const isUserSignedIn = !!isUserAuthenticated;

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={`${brand}-logo-app-bar logo-app-bar`}>
        <Toolbar>
          <Grid justify={'space-between'} container>
            <Grid xs={1} item>
              <div style={{ display: 'flex', width: 800 }}>
                <div>
                  <img src={images['logo-desktop']} alt="Logo" className={`${brand}-header-logo`} />
                </div>
                {!isMobile ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: 4,
                    }}
                  >
                    <Typography variant="h5" className={`${brand}-log-out-link`}>
                      {props.pageName}
                    </Typography>
                  </div>
                ) : null}
              </div>
            </Grid>
            <Grid xs={4} item>
              <Grid container justify={'center'}></Grid>
            </Grid>
            <Grid item xs={1} />
            <div className={`${brand}-header-data`}>
              <div style={{ display: 'flex' }}>
                {isUserSignedIn ? <SignOut brand={brand} fullName={profile.fullName}/> : <SignIn brand={brand} />}
              </div>
            </div>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
