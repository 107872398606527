import { useSelector } from 'react-redux';
import { getBrandImagesSelector, getBrandNameSelector, getLongBrandNameSelector } from '../BrandProvider/selectors';
import TextField from '@material-ui/core/TextField';
import { FormattedMessage } from 'react-intl';
import messages from '../LoginPage/messages';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import Step from './Step';
import { postResetPassword } from './http';

const useStyles = makeStyles(theme => ({
  form: {
    marginTop: theme.spacing(1),
  },
}));

type ViewState = 'default'|'submitted'|'resent';
const ForgotPasswordPage = () => {
  const history = useHistory();
  const brand = useSelector(getBrandNameSelector);
  const brandLongName = useSelector(getLongBrandNameSelector);
  const classes = useStyles();
  const [email, setEmail] = useState<string>('');
  const [viewState, setViewState] = useState<ViewState>('default');
  const images = useSelector(getBrandImagesSelector);

  let optionalNightOwlInputProps = {};
  if (brand === 'nightowl') {
    optionalNightOwlInputProps = {
      InputLabelProps:{
        shrink: true
      }
    }
  }
  
  const fetchPasswordReset = async () => {
    try {
      // Currently if a user exists or not, they are sent to the "email sent" view, even if the API returns "user doesn't exist"
      await postResetPassword(brand, {
        template: "",
        email
      });
    } catch (error) {
      // @TODO should we design error handling for this flow at this point?
    }
  }
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await fetchPasswordReset();
    setViewState('submitted')
  };
  
  const handleResend = async () => {
    await fetchPasswordReset();
    setViewState('resent');
  }
  
  const ResetForm = () => (
    <form className={classes.form} noValidate onSubmit={handleSubmit}>
      <TextField
        {...optionalNightOwlInputProps}
        className={`${brand}-text-field`}
        variant="outlined"
        margin="normal"
        fullWidth
        id="email"
        label={<FormattedMessage {...messages.username} />}
        name="email"
        autoComplete="email"
        value={email}
        onChange={(e: any) => {
          setEmail(e.target.value);
        }}
        autoFocus
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={!email ? `${brand}-login-button-disabled` : `${brand}-login-button`}
        id="resetPassword"
        style={{
          textTransform: 'none',
          fontWeight: 'bolder',
          fontSize: 'larger',
          marginTop: 24,
        }}
        disabled={!email}>
        Send Reset Link
      </Button>
    </form>
  )

  const steps = {
    default: {
      imgSrc: images['instruct_setup_forgot-password'],
      header: 'Reset password?',
      body: (
        <>
          <p>Enter your {brandLongName} email address and we'll email you a secure link to reset your password.</p>
          <ResetForm />
        </>
      ),
      secondaryButton: {
        copy: 'Return to Login Page',
        callback: () => history.push('/')
      }
    },
    submitted: {
      imgSrc: images['email-verification'],
      header: 'Check your email',
      body: (
        <>
          <p>If there’s an account associated with {email}, you’ll receive an email with a secure link to reset
            your password.</p>
          <p>It may take a few minutes for the email to arrive.</p>
        </>
      ),
      primaryButton: {
        copy: 'Return to Login',
        callback: () => history.push('/')
      },
      secondaryButton: {
        copy: 'Resend Reset Link',
        callback: handleResend
      }
    },
    resent: {
      imgSrc: images['email-verification'],
      header: 'Email resent',
      body: (
        <>
          <p>If there’s an account associated with {email}, you’ll receive an email with a secure link to reset your
            password.</p>
          <p>If you do not receive an email, try using a different email address or contacting support. </p>
        </>
      ),
      primaryButton: {
        copy: 'Return to Login',
        callback: () => history.push('/')
      },
      secondaryButton: {
        copy: 'Contact Support',
        callback: () => window.open('https://static.zdassets.com/web_widget/latest/liveChat.html?v=10#key=nowlsp.zendesk.com', '_blank')
        // @TODO if we add more brands that support "forgot password" flow, make the above link brand-dynamic
      }
    },
  }
  
  return (
    <div className={`${brand}-forgot-password`}>
      <div className={`${brand}-forgot-password-content`}>
        <Step brand={brand} {...steps[viewState]} />
      </div>
    </div>
  )
}

export default ForgotPasswordPage;